.contact {
    margin-bottom: 80px;
    position: relative;
    &__img {
        display: block;
        margin: 0 auto;
        margin-bottom: 74px;
        padding: 31px 0;
        z-index: 4;
        transform: scale(1.0);
        transition: 1.5 all;
        &:hover {
            cursor: pointer;
            transform: scale(0.9);
        }
    }
    &__corner {
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
    }
    &__text {
        margin-bottom: 30px;
        color: #393939;
        font-size: 30px;
        font-weight: 800;
        line-height: 36px;
        text-align: center;
    }
    &__button {
        display: block;
        margin: 0 auto;
        width: 241px;
        height: 60px;
        box-shadow: 0 0 7px rgba(0, 0, 0, 0.07);
        background-color: #00c2f0;
        color: #ffffff;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        cursor: pointer;
        border-radius: 5%;
        outline: none;
    }
}