@media (max-width: 1199px) {
   .promo {
       height: 600px;
       padding-top: 10px;
       &__img {
           width: 35%;
           top: 36.3%;
           left: 66%;
       }
       &__titles {
           margin-top: 60px;
       }
       .slick-slide img {
           height: 600px;
       }
       .slick-prev, .slick-next {
           img {
            width: 100%;
            height: 100%;
           }
       }
       .slick-next  {
        left: 55%;
        }
   }
   .options {
       &__imgcorner {
           left: 68%;
           &_options {
               left: 64%;
           }
       }
   }
   .decisions {
       &__air {
           left: -2.8%;
       }
       &__img {
        .slick-dots {
            left: 3%;
            }
        .slick-next {
            left: 98.5%;
            }
        .slick-prev {
            left: -2%;
        }
       }
   }
   .advantages {
       margin: 40px;
       &__title {
           margin-bottom: 20px;
       }
   }
   .variants {
       margin-bottom: 30px;
       &__title {
           left: 35%;
       }
   }
}

@media (max-width: 991px) {
    .md_invisible {
        display: none;
    }
    .header {
        &__tel {
            h4 {
                font-size: 10px;
            }
        }
    }
    .promo {
        height: 500px;
        padding: 0;
        &__header {
            ul {
                li {
                    padding: 0 10px;
                    a {
                        font-size: 12px;
                        font-weight: 400;
                    }
                }
            }
        }
        &__titles {
            margin-top: 30px;
        }
        &__title {
            font-size: 36px;
            line-height: 36px;
        }
        &__img {
            top: 51.1%;
        }
        .slick-slide img {
            height: 500px;
        }
    }
    .options {
        &__descript {
            &_md {
                display: block;
            }
        }
        &__imgcorner {
            left: 54.5%;
            &_options {
                left: 49.5%;
            }
        }
    }
    .decisions {
        &__air {
            width: 100%;
            left: 0;
        }
        &__tabs {
            width: auto;
        }
        &__img {
            .slick-dots {
                top: -39.5%;
                left: 0;
                li {
                    button {
                        width: 120px;
                        font-size: 11px;
                        }
                }
                :nth-child(2) {
                    button {
                        width: 100px;
                    }
                }
                :nth-child(3) {
                    button {
                        width: 115px;
                    }
                }
                :nth-child(4) {
                    button {
                        width: 110px;
                    }
                }
                :nth-child(5) {
                    button {
                        width: 85px;
                    }
                }
                :nth-child(6) {
                    button {
                        width: 88px;
                    }
                }
            }
        }
        .slick-slide {
            div {
                div {
                    img {
                        width: 75%;
                    }
                }
            }
        }
    }
    .variants {
        &__title {
            left: 25%;
        }
        &__img {
            top: 37%;
        }
        &__subtitle {
            font-size: 25px;
        }
        &__descr {
            font-size: 14px;
        }
    }
}

@media (max-width: 767px) {
    .sm_invisible {
        display: none;
    }
    .sm_visible {
        display: block;
    }
    .promo {
        max-height: 380px;
        &__top {
            width: 105%;
            transform: translateY(-130%);
        }
        &__header {
            margin-top: 5px;
            margin-bottom: 55px;
            ul {
                flex-direction: column;
                padding-top: 0;
                :first-child {
                    padding-left: 10px;
                }
                :last-child {
                    padding-right: 10px;
                }
            }
        }
        .slick-slide {
            img {
                max-height: 380px;
            }
        }
        &__titles {
            margin: 0;
        }
        &__title {
            font-size: 30px;
        }
        &__img {
            width: 30%;
            top: 59.1%;
            left: 70%;
        }
    }
    .menu {
        position: fixed;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        top: -34%;
        left: -100%;
        width: 40%;
        height: 75vh;
        padding: 20px 15px;
        background-color: rgba(0, 0, 0, 0.8);
        transition: 0.6s all;
        z-index: 2;
    }
    .menu_active {
        left: -10%;
    }
    .menu_item {
        padding-right: 0;
        border: none;
        margin-top: 20px 0;
    }
    .menu_link {
        font-size: 20px;
    }
    .hamburger {
        display: block;
        position: absolute;
        right: 10px;
        top: 5%;
        transform: translateY(-50%);
        width: 26px;
        height: 12px;
        span {
            display: block;
            height: 2px;
            width: 100%;
            background-color: #fff;
            margin-bottom: 3px;
            transition: 0.5s all;
        }
    }
    .hamburger_active {
        span {
            margin-bottom: -3px;
        }
        span:nth-child(1) {
            transform: translateY(4px) rotate(-45deg);
        }
        span:nth-child(2) {
            display: none;
        }
        span:nth-child(3) {
            transform: translateY(4px) rotate(45deg);
        }
    }
    .options {
        height: 100%;
        margin-bottom: 20px;
        &__questions {
            margin-top: 30px;
        }
        &__options, &__questions {
            flex-direction: column;
        }
        &__option, &__question {
            margin: 0;
        }
        &__imgcorner {
            left: 75%;
            &_options {
                left: 72%;
            }
        }
    }
    .variants {
        &__title {
            left: 20%;
        }
        &__subtitle {
            font-size: 20px;
        }
        &__descr {
            font-size: 11px;
        }
    }
    .decisions {
        padding-top: 0;
        &__header {
            font-size: 25px;
        }
        &__tabs {
           ul {
                li {
                    button {
                        width: 72px;
                        font-size: 10px;
                        }
                }
                :nth-child(2) {
                    button {
                        width: 40px;
                    }
                }
                :nth-child(3) {
                    button {
                        width: 55px;
                    }
                }
                :nth-child(4) {
                    button {
                        width: 50px;
                    }
                }
                :nth-child(5) {
                    button {
                        width: 33px;
                    }
                }
                :nth-child(6) {
                    button {
                        width: 38px;
                    }
                }
           }
        }
        &__img {
            .slick-dots {
                top: -54%;
                left: 0;
                li {
                    button {
                        width: 85px;
                        font-size: 11px;
                        }
                }
                :nth-child(2) {
                    button {
                        width: 70px;
                    }
                }
                :nth-child(3) {
                    button {
                        width: 90px;
                    }
                }
                :nth-child(4) {
                    button {
                        width: 90px;
                    }
                }
                :nth-child(5) {
                    button {
                        width: 55px;
                    }
                }
                :nth-child(6) {
                    button {
                        width: 58px;
                    }
                }
            }
        }
    }
    hr {
        margin: 32px 0 24px;
    }
    .footer {
        &__modules {
            display: none;
        }
    }
}
@media (max-width: 575px) {
    .esm_invisible {
        display: none;
    }
    .promo {
        &__top {
            transform: translateY(-115%);
        }
        &__title {
            font-size: 24px;
        }
        &__button {
            width: auto;
            height: 40px;
            margin-top: 100px;
            font-size: 15px;
        }
        &__img {
            top: 65.15%;
            left: 60%;
            width: 121px;
        }
    }
    .menu {
        top: -1%;
        left: -100%;
        width: 59%;
        height: 65vh;
    }
    .menu_active {
        left: -5%;
    }
    .options {
        &__question, &__option {
            max-width: 320px;
        }
        &__imgcorner {
            left: 72%;
            &_options {
                left: 68%;
            }
        }
    }
    .decisions {
        &__header {
            font-size: 20px;
        }
        &__tabs {
            display: none;
        }
    }
    .advantages {
        margin: 0;
        &__items {
            margin-bottom: 30px;
        }
        &__title {
            margin-bottom: 0;
            font-size: 24px;
        }
        &__descr {
            font-size: 16px;
            line-height: 20px;
        }
    }
    .variants {
        &__title {
            top: 5%;
            left: 15%;
        }
        &__subtitle {
            font-size: 18px;
            text-align: right;
            &_right {
                text-align: left;
            }
        }
        &__item {
            height: 420px;
        }
        &__descr {
            line-height: 12px;
        }
        &__img {
            width: 130px;
            top: 35%;
        }
    }
    .footer {
        &__about {
            margin-right: 20px;
        }
    }
    .pageup {
        right: 5px;
    }
}