.options {
    height: 820px;
    &__questions, &__options {
        display: flex;
        justify-content: space-between;
        align-items: center;
        :last-child {
            margin-right: 0;
        }
    }
    &__questions {
        margin: 79px 0 50px;
    }
    &__question, &__option {
        position: relative;
        width: 360px;
        height: 255px;
        margin-right: 30px;
        border: 1px solid #ececec;
    }
    &__descr {
        display: block;
        div {
            text-align: center;
        }
    }
    &__img {
        display: block;
        margin: 0 auto;
        height: 180px;
    }
    &__arrow {
        display: block;
        margin: 0 auto;
        margin-bottom: 33px;
    }
    &__sprengi {
        text-align: center;
        margin-bottom: 40px;
    }
    &__imgcorner {
        position: absolute;
        top: 64%;
        left: 74%;
        &_options {
            left: 70%;
        }
    }
    &__descript {
        color: #393939;
        font-size: 18px;
        font-weight: 300;
        line-height: 26px;
        &_md {
            color: #393939;
            font-size: 18px;
            font-weight: 300;
            line-height: 26px;
            display: none;
        }
    }
    &__sprengi {
        font-size: 48px;
        font-weight: 700;
        line-height: 30px;
        text-transform: uppercase;
    }
}