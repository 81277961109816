.feed-form {
    margin-top: 36px;
    display: flex;
    flex-direction: column;
    width: 300px;
    input {
        height: 50px;
        border-radius: 5px;
        background-color: #ffffff;
        margin-bottom: 15px;
        border: none;
        padding: 0 20px;
        color: #4b4b4b;
        font-size: 14px;
        font-weight: 500;
        &:nth-child(3) {
            margin-bottom: 20px;
        }
    }
    &_mt25 {
        margin-top: 25px;
    }
}

.overlay {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    background-color:rgba(0, 0, 0, 0.56);
    z-index: 5;
}
.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 380px;
    min-height: 400px;
    background-color: #f2f2f2;
    padding: 38px 40px 40px;
    &__close {
        position: absolute;
        top: -3px;
        right: -40px;
        font-size: 41px;
        line-height: 21px;
        color: #c70101;
        cursor: pointer;
    }
    &__subtitle {
        color: #0d0d0d;
        font-size: 18px;
        font-weight: 700;
        text-align: center;
    }
    &__descr {
        color: #0d0d0d;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
    }
    &__button {
        height: 60px;
        box-shadow: 0 0 7px rgba(0, 0, 0, 0.07);
        background-color: #00c2f0;
        color: #ffffff;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        cursor: pointer;
        border-radius: 5%;
        outline: none;
        padding: 0;
    }
    &_mini {
        min-height: 140px;
    }
    .error {
        border: 1px solid red;
    }
    label.error {
        border: none;
        text-align: center;
        margin-bottom: 10px;
    }
}
#access, #thanks {
    display: none;
}