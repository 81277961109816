.variants {
    position: relative;
    margin-bottom: 80px;
    &__title {
        position: absolute;
        top: 12.6%;
        left: 40.1%;
        color: #ffffff;
        font-size: 30px;
        font-weight: 700;
        line-height: 35.99px;
        text-align: center;
        z-index: 1;
    }
    &__items {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-height: 580px;
    }
    &__item {
        position: relative;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 50%;
        height: 580px;
        background: url('../img/variant_bg_1.png') center center/cover no-repeat, linear-gradient(to bottom left, #B9D8F2, #F6D2F5);
        background-color: rgba(0,0,0,.33);
        background-blend-mode: multiply;
        &_right {
            justify-content: flex-start;
            background: url('../img/variant_bg_2.png') center center/cover no-repeat, linear-gradient(to bottom left, #B9D8F2, #F6D2F5);
        }
    }
    &__square {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        padding-right: 8%;
        padding-top: 5%;
        z-index: 2;
        &_right {
            align-items: flex-start;
            padding-right: 0;
            padding-left: 8.2%;
        }
    }
    &__subtitle {
        color: #ffffff;
        font-size: 30px;
        font-weight: 700;
        text-transform: uppercase;
        padding-bottom: 2.8%;
    }
    &__descr {
        color: #ffffff;
        font-size: 18px;
        font-weight: 300;
        line-height: 26px;
        text-align: right;
        &_right {
            text-align: left;
        }
    }
    &__img {
        position: absolute;
        top: 40%;
        right: 52.3%;
        &_right {
            left: 52.3%;
        }
    }
}