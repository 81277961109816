.header {
    padding: 19px 0;
    &__items {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__item {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }
    &__address {
        padding-left: 11px;
    }
    &__country {
        font-size: 15px;
        font-weight: 700;
    }
    &__tel {
        display: block;
        h4 {
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
        }
    }
}