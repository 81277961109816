.pageup {
    display: none;
    position: fixed;
    bottom: 40px;
    right: 40px;
    width: 40px;
    height: 40px;
    z-index: 3;
    img {
        width: 100%;
    }
}