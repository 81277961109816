@import "helpers/variables";
@import "helpers/mixins";
@import "helpers/functions";
@import "vendor/libs";
@import "base/fonts";
@import "base/general";
@import "../blocks/modules";
@import "base/media";

* {
    font-family: 'Roboto', sans-serif;
    box-sizing: border-box;
}