.decisions {
    max-height: 889px;
    padding: 74px 0 62px 0;
    background: url('../img/bg_decision.png') center center/cover no-repeat;
    background-color: rgba(0,0,0,.33);
    background-blend-mode: darken;
    &__header {
        text-align: center;
        color: #ffffff;
        font-size: 30px;
        font-weight: 700;
        line-height: 35.99px;
        margin-bottom: 25px;
    }
    &__tabs {
        display: block;
        margin: 0 auto;
        width: 843px;
        height: 65px;
        margin-bottom: 70px;
        border: 1px solid #fff;
        border-radius: 4px;
        ul {
           display: flex;
           justify-content: center;
           align-items: center;
           height: 100%;
           padding:6px 6px;
           list-style-type: none;
           :last-child {
               border: none;
           }
           li {
               height: 100%;
               padding: 0 20px;
               border-right: 1px solid rgba($color: #fff, $alpha: 0.3);
                :hover {
                    color: #00c2f0;
                    font-weight: 500;
                }
               button {
                height: 100%;
                background-color: rgba($color: #fff, $alpha: 0.01);
                color: rgba($color: #fff, $alpha: 0.01);
                font-size: 14px;
                font-weight: 400;
                border: none;
                outline: none;
                cursor: pointer;
               }
           }
        }
    }
    &__mac {
        position: relative;
    }
    &__img {
        img {
            display: block;
            margin: 0 auto;
        }
        .slick-prev, .slick-next  {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 4%;
            border: none;
            background-color: rgba($color: #fff, $alpha: 0.01);
            z-index: 3;
            cursor: pointer;
            img {
                width: 18px;
                height: 60px;
            }
        }
        .slick-next  {
            left: 93%;
        }
        .slick-dots {
            position: absolute;
            top: -28.5%;
            left: 11%;
            font-family: 'Roboto';
            display: flex;
            justify-content: space-around;
            align-items: center;
            border: none;
            background: none;
            z-index: 2;
            li {
                list-style-type: none;
                margin: 0 3px;
                :hover {
                    color: #00c2f0; 
                }
                button {
                    width: 166px;
                    height: 60px;
                    padding: 0;
                    cursor: pointer;
                    background-color: rgba($color: #fff, $alpha: 0.01);
                    outline: none;
                    border: none;
                    font-size: 14px;
                    font-weight: 400;
                    color: #fff;
                }
            }
            .slick-active {
                button {
                    color: #04046C;
                }
            }
            :nth-child(2) {
                button {
                    width: 120px;
                }
            }
            :nth-child(3) {
                button {
                    width: 162px;
                }
            }
            :nth-child(4) {
                button {
                    width: 140px;
                }
            }
            :nth-child(5) {
                button {
                    width: 102px;
                }
            }
            :nth-child(6) {
                button {
                    width: 118px;
                }
            }
        }
    }
    &__air {
        position: absolute;
        top: -8%;
        left: 6.2%;
    }
}