.promo {
    position: relative;
    box-sizing: border-box;
    height: 700px;
    padding: 0 0 93px 0;
    &__top {
        position: relative;
        transform: translateY(-144%);
    }
    &__wrapper {
        position: relative;
        top: 0%;
        z-index: 2;
    }
    &__header {
        display: flex;
        justify-content: space-between;
        align-self: flex-start;
        margin-top: 18px;
        ul {
            display: flex;
            padding: 25px 0;
            list-style-type: none;
            li {
                padding: 0 19px;
                a {
                    cursor: pointer;
                    color: #fff;
                    font-size: 13px;
                    font-weight: 700;
                    text-transform: uppercase;
                    text-decoration: none;
                }
            }
            :first-child {
                padding-left: 0;
            }
            :last-child {
                padding-right: 0;
            }
        }
    }
    &__titles {
        position: relative;
        margin-top: 130px;
    }
    &__title {
        color: #ffffff;
        font-size: 42px;
        font-weight: 700;
        line-height: 48px;
    }
    &__subtitle {
        h2 {
            color: #ffffff;
            font-size: 24px;
            font-weight: 300;
            line-height: 30px;
            margin-top: 23px;
            span {
                font-weight: 400;
            }
        }
    }
    &__button {
        width: 241px;
        height: 60px;
        box-shadow: 0 0 7px rgba(0, 0, 0, 0.07);
        background-color: #00c2f0;
        color: #ffffff;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        cursor: pointer;
        border-radius: 5%;
        outline: none;
        padding: 0;
        margin-top: 36px;
    }
    &__img {
        position: absolute;
        top: 19.5%;
        left: 60%;
    }
    .slick-prev, .slick-next  {
        position: absolute;
        top: 89.5%;
        transform: translateY(-50%);
        left: 38.5%;
        border: none;
        background-color: rgba($color: #fff, $alpha: 0.01);
        z-index: 3;
        img {
            width: 6px;
            height: 11px;
        }
    }
    .slick-next  {
        left: 47%;
    }
    .slick-dots {
        position: absolute;
        top: 88.3%;
        left: 40%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        z-index: 4;
        border: none;
        background: none;
        .slick-active {
            button {
                background-color: #fff;
            }
        }
        li {
            list-style-type: none;
            margin: 0 3px;
            font-size: 1px;
            button {
                width: 15px;
                height: 15px;
                padding: 0;
                border: 2px solid #fff;
                border-radius: 100%;
                background-color: rgba($color: #fff, $alpha: 0.01);
            }
        }
    }
}
.hamburger {
    display: none;
}