.footer {
    max-height: 497px;
    background-color: #0B829B;
    padding: 49px 0 40px;
    &__sections {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        color: #ffffff;
        ul {
            li:nth-child(1) {
                font-size: 16px;
                font-weight: 700;
            }
            li {
                list-style-type: none;
                margin: 3px 0;
                a {
                    color: #ffffff;
                    font-size: 14px;
                    font-weight: 300;
                    text-decoration: none;
                }
            }
        }
    }
    &__about {
        max-width: 377px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    &__title {
        font-size: 16px;
        font-weight: 700;
    }
    &__logo {
        margin: 15px 0;
    }
    &__text, &__aboutdown {
        font-size: 14px;
        font-weight: 300;
        line-height: 19px;
        a {
            color: #ffffff;
            text-decoration: none;
        }
    }
    &__aboutdown {
        margin-top: 5px;
    }
    &__down {
        color: #ffffff;
        font-size: 13px;
        font-weight: 300;
        text-align: center;
    }
}
hr {
    height: 1px;
    opacity: 0.3;
    margin: 44px 0 36px;
}