/* This stylesheet generated by Transfonter (https://transfonter.org) on February 25, 2018 4:00 PM */
@font-face {
    font-family: 'Roboto';
    font-display: swap;
    src: local('Roboto Bold'), local('Roboto-Bold'),
        url('../../fonts/Roboto-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    font-display: swap;
    src: local('Roboto'), local('Roboto-Regular'),
        url('../../fonts/Roboto-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    font-display: swap;
    src: local('Roboto Medium'), local('Roboto-Medium'),
        url('../../fonts/Roboto-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    font-display: swap;
    src: local('Roboto Black'), local('Roboto-Black'),
        url('../../fonts/Roboto-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    font-display: swap;
    src: local('Roboto Light'), local('Roboto-Light'),
        url('../../fonts/Roboto-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    font-display: swap;
    src: local('Roboto Thin'), local('Roboto-Thin'),
        url('../../fonts/Roboto-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}