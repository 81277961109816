.advantages {
    margin: 80px 0;
    .container {
        padding: 0;
    }
    &__title {
        color: #393939;
        font-size: 30px;
        font-weight: 800;
        line-height: 35.99px;
        text-align: center;
        margin-bottom: 44px;
    }
    &__items {
        max-height: 1055px;
        display: flex;
        justify-content: center;
        align-items: stretch;
        flex-wrap: wrap;
        margin-bottom: 90px;
    }
    &__item {
        position: relative;
        width: 50%;
        height: 355px;
        &_1 {
            background: url('../img/advantage_bg_1.png') center center/cover no-repeat, linear-gradient(to bottom left, #B9D8F2, #F6D2F5);
            background-color: rgba(0,0,0,.33);
            background-blend-mode: multiply;
        }
        &_2 {
            background: url('../img/advantage_bg_2.png') center center/cover no-repeat, linear-gradient(to bottom left, #B9D8F2, #F6D2F5);
            background-color: rgba(0,0,0,.33);
            background-blend-mode: multiply;
        }
        &_3 {
            background: url('../img/advantage_bg_3.png') center center/cover no-repeat, linear-gradient(to bottom left, #B9D8F2, #F6D2F5);
            background-color: rgba(0,0,0,.33);
            background-blend-mode: multiply;
        }
        &_4 {
            background: url('../img/advantage_bg_4.png') center center/cover no-repeat, linear-gradient(to bottom left, #B9D8F2, #F6D2F5);
            background-color: rgba(0,0,0,.33);
            background-blend-mode: multiply;
        }
        &_5 {
            background: url('../img/advantage_bg_5.png') center center/cover no-repeat, linear-gradient(to bottom left, #B9D8F2, #F6D2F5);
            background-color: rgba(0,0,0,.33);
            background-blend-mode: multiply;
        }
        &_6 {
            background: url('../img/advantage_bg_6.png') center center/cover no-repeat, linear-gradient(to bottom left, #B9D8F2, #F6D2F5);
            background-color: rgba(0,0,0,.33);
            background-blend-mode: multiply;
        }
    }
    &__descr {
        position: absolute;
        top: 27%;
        width: 100%;
        height: 156px;
        background-color: rgba($color: #000000, $alpha: .44);
        color: #ffffff;
        font-size: 23px;
        font-weight: 400;
        line-height: 26px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}